// @flow
import React from 'react';
import Layout from '../../components/layout';
import type { FrontMatter } from '../../utils/types';
import { withFrontMatter } from '../../components';
import GlossaryLink from '../../components/glossary-link';
import Link from '../../components/link';
import route from '../../routes';

type Props = {
  frontMatter: FrontMatter,
};

class Blog_2019_06_25_Worrier extends React.PureComponent<Props> {
  render() {
    const { frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter}>
        <p>
          Everyone I have met while doing advanced canyons has some special
          ability/super power that they bring to the canyons. There are many
          canyoneers who are amazing{' '}
          <GlossaryLink id="down-climb">down climbers</GlossaryLink>. Some are
          really knowledgeable with{' '}
          <GlossaryLink id="rigging">rope work</GlossaryLink>, navigation,
          photography, some are really fit. Some are big and can{' '}
          <GlossaryLink id="partner-capture">capture</GlossaryLink> and lift
          people, some are small and are the first people out of{' '}
          <GlossaryLink id="keeper-pothole">potholes</GlossaryLink> and the{' '}
          <GlossaryLink id="last-person-at-risk">
            last people on rappel
          </GlossaryLink>
          . My greatest power is that I worry.
        </p>
        <p>
          My first time leading{' '}
          <Link to="http://ropewiki.com/Zero_G_Canyon">Zero Gravity</Link> -{' '}
          <Link to={route.blog_2012_04_28_my_story}>my first canyon</Link> by
          the way, I remember realizing that there is a keeper pothole we would
          have to pass and being absolutely terrified the days prior to the trip
          - even though I had already been in the canyon before. My first time
          going through a skinny slot was{' '}
          <Link to="http://ropewiki.com/Leprechaun_Canyon_(East_Fork)">
            East Leprechaun
          </Link>
          . I remember pouring over <GlossaryLink>beta</GlossaryLink> and
          pictures the weeks before our trip to make sure we were completely
          prepared.
        </p>
        <p>
          I have nightmares the night before and after{' '}
          <em>every single trip</em> - no matter if it's an exploration where I
          have no beta or if it's a canyon I've{' '}
          <GlossaryLink id="solo">soloed</GlossaryLink>. I still have nightmares
          where I slip while <GlossaryLink>stemming</GlossaryLink>, bounce a
          couple of times on my way down, and then wonder how I got to the
          bottom of a canyon and why I am in so much pain. Of course when I am
          descending the canyons I feel perfectly fine, even at the{' '}
          <GlossaryLink>crux</GlossaryLink>.
        </p>
        <p>
          When I prepare for trips I spend an enormous amount of time looking at
          possible <GlossaryLink id="approach">approaches</GlossaryLink>,
          landmarks to watch for, longest <GlossaryLink>rappels</GlossaryLink>,
          possible escapes, etc. And of course when I go to the canyon I never
          even pull out the notes I took because I have everything memorized. I
          often get people asking me if I have descended the canyon before just
          because I seem to know the route so well.
        </p>
        <hr />
        <p>
          I worry that someone in my group will get injured. Will I be able to
          keep a level head? Will we be able to get them out safely or get{' '}
          <GlossaryLink id="search-and-rescue">SAR</GlossaryLink> here in time?
          I keep up to date on my Wilderness First Aid certification and have
          been lucky enough to never need to use it, but will that be enough?
          Should I get my Wilderness First Responder? Wilderness EMT?
        </p>
        <p>
          What if we encounter a pothole so massive we cannot easily escape it?
          What if we encounter a drop and there is absolutely no way to rig a
          rappel? What if we come to a slot so skinny we can't fit through but
          it's impossible to get up above it?
        </p>
        <p>
          These questions have led me down the path to planning more, being more
          prepared, safer, more efficient, and hopefully being a valuable asset
          to my group.
        </p>
        <p>
          So what's the point of this article? I suppose it would be a challenge
          to become super good at <em>something</em> so you can become an asset
          to your team. And then invite me on one of your trips, of course :D
        </p>
      </Layout>
    );
  }
}

export default withFrontMatter('2019-06-25-worrier')(Blog_2019_06_25_Worrier);
